import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import "./ImageUpload.css";
import { baseURL } from "../../utilities/Config";
import { toast } from "react-toastify";

function AddEditImage({ text, handleImages, editable, EditIconImage }) {
    const toastBC = React.useRef(null);
    const upload = React.useRef(null);
    const [files, setFiles] = useState();
    const [icon, setIcon] = useState(editable);
    const [imgBase64, setImgBase64] = useState("");
    const [loading, setLoading] = useState(false);

    const header = () => {
        return (
            <>
                <input type="file" accept="image/png, image/gif, image/jpeg" style={{ display: "none" }} ref={upload} onChange={(e) => onFileChange(e)} />
                <Button
                    style={{ backgroundColor: "#63C56E", border: "1px solid #63C56E" }}
                    onClick={(e) => {
                        e.preventDefault();
                        upload.current.click();
                    }}
                    label={text ? text : "Choose File"}
                ></Button>
            </>
        );
    };

    const onFileChange = async (e) => {
        e.preventDefault();
        for (let file of e.target.files) {
            if (!(file && file.type.match("image.*"))) {
                console.log("Not an image file");
                return;
            }
        }
        setLoading(true);
        if (e.target.files[0]) await getBase64(e.target.files[0]);
        setLoading(false);
    };

    async function getBase64(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const fileDataUrl = reader.result;
            const filetype = file.type.split("/");
            let singleFile = { fileBase64: reader.result, fileName: file.name, fileSize: file.size, fileExtension: `.${filetype[1]}` };
            setImgBase64(fileDataUrl);
            setFiles(singleFile, fileDataUrl);
        };
    }

    const handleRemove = (b64) => {
        setFiles("");
    };

    const handleIconRemove = () => {
        setIcon(false);
    };

    useEffect(() => {
        handleImages(files);
    }, [files]);

    return (
        <Panel className="Custom__Panel" header={header}>
            <Toast ref={toastBC} position="bottom-center" />
            <div className="formgrid grid">
                {icon && !files ? (
                    <>
                        <div className="field col-12 md:col-3">{EditIconImage && <img src={`${baseURL}${EditIconImage}`} width="60px" alt="img" />}</div>
                        <div className="field col-12 md:col-5 mt-2">
                            <p>{icon}</p>
                        </div>
                    </>
                ) : (
                    <center style={{ height: "3vh" }}></center>
                )}
                {files ? (
                    <>
                        <div className="field col-12 md:col-3">{files?.fileExtension === ".png" || files?.fileExtension === ".jpeg" ? <img src={files?.fileBase64} width="60px" alt="img" /> : null}</div>
                    </>
                ) : (
                    <center style={{ height: "3vh" }}></center>
                )}
                {loading && <h5>Loading</h5>}
            </div>
        </Panel>
    );
}

export default AddEditImage;
