//let baseURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_LIVE;

//For Local

// let baseURL = "http://192.168.13.130:3015/";


//For QA
// let baseURL = "https://qa-msafaapi.appinsnap.com/";

//For UAT
// let baseURL = "https://uat-msafaapi.appinsnap.com/";

//For Prod
let baseURL = "https://api.msafa.ca/";

export { baseURL };
